<template>
	<div class="index">
		<div class="container">
			
			<div class="box">
				<div class="item" @click="go('/Recharge')">我的余额</div>
				<div class="item" @click="go('/Task')">我的打印任务</div>
				<div class="item" @click="go('/CloudFile')">我的云文件</div>
			</div>
			<!-- <div @click="go('/demo')">测试</div> -->
		</div>


	</div>
</template>

<script>
	// @ is an alias to /src
	import Time from '@/components/time.vue'


	export default {
		name: 'Home',
		components: {
			Time
		},
		data() {
			return {}
		},
		created() {

		},
		methods: {
			go(url) {
				this.$router.push({
					path: url
				})
			},
		

		}
	}
</script>


<style scoped lang="scss">
	.index {


		.container {

			.top {
				color: #fff;
			}

			.box {

				width: 1000px;
				margin: 0 auto;
				display: flex;
				margin-top: 150px;
				justify-content: space-between;

				.item {
					width: 300px;
					height: 200px;
					background: #f1dd59;
					color: #fff;
					font-size: 40px;
					text-align: center;
					line-height: 200px;
					cursor: pointer;

					&:nth-child(1) {
						background: #18E3B3;
					}
					&:nth-child(3) {
						background-color: #59ddf1;
					}
					
					
				}
			}

		}


	}
</style>
